<template>
    <div class="layout">
        <header-bar :logined="logined"></header-bar>
        <div class="header-inner">
            <a href="/"><img :src="logoUrl" class="logo"></a>
                <div class="navSearchBox">
                        <form action="/search" target="_blank">
                            <a href="javascript:;" class="navSearchIcon" @click="goSearch">
                                <div class="navSearchIconIcon"></div>
                            </a>
                            <input type="text" class="navSearch placeholder" id="keyWord" name="q" onkeyup="_search(event)" placeholder="搜索您想了解的数据"/>
                        </form>
                    </div>
                <div class='clearfix'></div>
        </div>
        <div class='navwrap'>
            <div class='nav detailNav'>
                <div class='navBox'>
                    <ul class='navUl'>
                        <li class='navLi' v-for='ele in nav.content' @mouseenter='me(ele.name)' @mouseleave='ml' :key="ele.name">
                            <span class='navSpan' @click='toUrl(ele.url)'>{{ele.name}}</span>
                            <template v-show='ele.children.length!==0'>
                                <ul class='drag' v-show='nav.state==ele.name&&ele.children.length!==0'>
                                    <li class='dragLi' v-for='item in ele.children' @mouseenter='subme(item.name)'
                                    @mouseleave='subml' :key="item.name">
                                    <a class='dragLi-word' :href='item.url'>
                                            {{item.name}}
                                        </a>
                                        <template v-show='item.children!==[]'>
                                                <ul class='drag sub-drag' v-show='nav.substate==item.name&&item.children.length!==0'
                                                    @mouseleave='subml'>
                                                    <li class='dragLi' v-for='one in item.children' :key="one.value">
                                                        <a class='dragLi-word' :href="'/baseInfo/line_c/'+one.line_code" target="_blank">
                                                        {{one.line_name}}
                                                            <!-- <router-link :to="{name:'line_c',params:{line_code:one.line_code}}">{{one.line_name}}</router-link> -->
                                                        </a>
                                                    </li>
                                                </ul>
                                        </template>
                                    </li>
                                    <div class='dragAngel'>
                                    </div>
                                </ul>
                            </template>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        <router-view name="indexCarousel"></router-view>
        <div class="med-wrapper-box">
                <Breadcrumb class="bread-wrapper" separator=">">
                    <BreadcrumbItem v-for="ele in breadlist" :key="ele.name"><a :href="ele.link">{{ele.name}}</a></BreadcrumbItem>
                </Breadcrumb>
                    <div>
                        <Content class="m-content-wrapper">
                            <router-view name="leftBar"></router-view>
                            <router-view></router-view>
                        </Content>
                    </div>
            </div>

            <footer-bar></footer-bar>
        <!--<Layout>
            <Header class="npm">
                <Menu mode="horizontal" theme="dark" active-name="0">
                    <div class="layout-logo">
                        <a href='/'><img class='navImg' :src="logosrc" alt='' /></a>
                    </div>
                    <div class="layout-nav">
                        <ul class='navUl'>
                            <li class='navLi' v-for='ele in nav_content' @mouseenter='me(ele.name)' @mouseleave='ml' :key="ele.name">
                                <span class='navSpan'>
                                    <Icon :type="ele.icstyle"></Icon>
                                    <a :href="ele.url">{{ele.name}}</a>
                                </span>
                                <template v-show='ele.children!=[]'>
                                    <ul class='drag' v-show='nav.state==ele.name&&ele.children.length!==0' @mouseleave='ml'>
                                        <li class='dragLi' v-for='item in ele.children' @mouseenter='subme(item.name)'
                                            @mouseleave='subml' :key="item.name">
                                            <a class='dragLi-word' :href='item.url'>
                                                {{item.name}}
                                            </a>
                                            <template v-show='item.children!=[]'>
                                                <ul class='sub-drag' v-show='nav.substate==item.name&&item.children.length!==0'
                                                    @mouseleave='subml'>
                                                    <li class='dragLi' v-for='one in item.children' :key="one.value">
                                                        <a class='dragLi-word'>
                                                            <router-link :to="{name:'line_c',params:{line_id:one.value}}">{{one.name}}</router-link>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </li>
                                        <div class='dragAngel'>
                                        </div>
                                    </ul>
                                </template>
                            </li>
                            <li class='navLi' @mouseenter="me('login')" @mouseleave='ml'>
                                <Divider type="vertical" />
                                <span class='navSpan-login' v-if="logined">
                                    <Icon type="ios-contact" :size="24" />
                                </span>
                                <span class='navSpan-login' v-else>
                                    <a href="/login">登录</a>
                                </span>
                                <template v-if="logined">
                                    <ul class='drag drag-login' v-show="nav.state=='login'" @mouseleave='ml'>
                                        <li class='dragLi dragLi-login' v-for='item in loginAuth' :key="item.value">
                                            <a class='dragLi-word' :href="'/entry/'+item.value">
                                                {{item.text}}
                                            </a>
                                        </li>
                                        <li class='dragLi dragLi-login' @click="LogOut">
                                            <a class='dragLi-word'>
                                                注销登录
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <Divider type="vertical" />
                            </li>
                        </ul>
                    </div>
                </Menu>
            </Header>
            <router-view name="indexCarousel"></router-view>
            <Layout class="med-wrapper-box">
                <Breadcrumb :style="{margin: '16px 10px'}" separator=">">
                    <BreadcrumbItem v-for="ele in breadlist" :key="ele.name"><a :href="ele.link">{{ele.name}}</a></BreadcrumbItem>
                </Breadcrumb>
                <Content :style="{padding: '24px 0', minHeight: '280px', background: '#fff'}">
                    <Layout>
                        <Content class="m-content-wrapper">
                            <router-view name="leftBar"></router-view>
                            <router-view></router-view>
                        </Content>
                    </Layout>
                </Content>
            </Layout>

            <footer-bar></footer-bar>
        </Layout>-->
    </div>
</template>
<script>
    import footerBar from './FooterBar.vue'
    import headerBar from './HeaderBar.vue'

    export default {
        name: "mainFrame",
        components: {
            footerBar,
            headerBar
        },
        data() {
            return {
                imgUrl: process.env.BASE_URL + "img/login_bg.jpg",
                logoUrl: process.env.BASE_URL + "img/city_logo/logo.png",
                /*nav: {
                    state: "",
                    substate: "",
                    content: this.nav_content
                },*/
                nav: {
                    state: '',
                    substate: '',
                    content: [
                        {
                            name: '首页',
                            url: '/index',
                            children: []
                        },
                        {
                            name: '线网基本信息',
                            url: undefined,
                            code:'line',
                            children: [
                                { name: "线路", url: undefined, children: [] },
                                { name: "网络", url: '/baseInfo/netDescription', children: [] },
                            ],
                        },
                        // {
                        //     name: '运营基础指标',
                        //     url: '/baseInfo/operationData',
                        //     children: [
                               
                        //     ],
                        // },
                        // {
                        //     name: '网络化运营',
                        //     url: undefined,
                        //     children: [
                        //         { name: "控制中心功能", url: "/network/center", children: [] },
                        //         { name: "应急能力梳理", url: "/network/emergency", children: [] },
                        //         { name: "客流专项分析", url: "/network/passengerFlow", children: [] },
                        //     ],
                        // },
                        // {
                        //     name: '评估期间数据',
                        //     url: undefined,
                        //     children: [
                        //     { name: "风险分级管控", url: "/period/period_risk", children: [] },
                        //     { name: "隐患排查治理", url: "/period/latent_danger_l", children: [] },
                        //     { name: "险性事件分析", url: "/period/accident_l", children: [] },
                        //     ]
                        // },
                        {
                            name: '安全评估结论',
                            url: undefined,
                            children: [
                            { name: "风险点数据库", url: "/conclusion/risk_l", children: [] },
                            { name: "安评报告查询", url: "/conclusion/report_l", children: [] },
                            { name: "风险整改进度", url: "/conclusion/correct_l", children: [] },
                            ]
                        },
                        
                        {
                        name: "专业专家信息",
                        url: "",
                        children: [
                            { name: "安全管理", url: "/archive/expert/safe", children: [] },
                            { name: "信号专业", url: "/archive/expert/signal", children: [] },
                            { name: "土建专业", url: "/archive/expert/construction", children: [] },
                            { name: "供电专业", url: "/archive/expert/electricity", children: [] },
                            { name: "机电专业", url: "/archive/expert/mechatronics", children: [] },
                            { name: "通信专业", url: "/archive/expert/communication", children: [] },
                            { name: "运营组织", url: "/archive/expert/organization", children: [] },
                            { name: "车辆专业", url: "/archive/expert/train", children: [] },
                        ]
                        },
                        {
                            name: '评价规范汇总',
                            url: "/archive/rule_l",
                            children: [
                                /**{ name: "法规标准", url: "/archive/rule_l", children: [] },
                                { name: "特定规则", url: "/archive/rule_l", children: [] },**/
                            ]
                        },
                    ]
                },
                logined: false,
                loginAuth: [],
                nav_content: [],

            }
        },
        created() {
            this.get_line();
        },
        mounted() {
        },
        props: {
            breadlist: {
                type: Array,
                default() {
                    if (this.$route.path.split('/')[2] == 'line_c') {
                        return [{ name: "主页", link: '/' },{ name: "线网基本信息", link: '' },  { name: "线路", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'risk_l' || this.$route.path.split('/')[2] == 'risk_l') {
                        return [{ name: "主页", link: '/' }, { name: "安全评估结论", link: '' }, { name: "风险点数据库", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'correct_l' || this.$route.path.split('/')[2] == 'correct_l') {
                        return [{ name: "主页", link: '/' }, { name: "安全评估结论", link: '' }, { name: "风险整改进度", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'report_l') {
                        return [{ name: "主页", link: '/' }, { name: "安全评估结论", link: '' }, { name: "安评报告查询", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'accident_l') {
                        return [{ name: "主页", link: '/' }, { name: "评估期间数据", link: '' }, { name: "险性事件分析", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'latent_danger_l') {
                        return [{ name: "主页", link: '/' }, { name: "评估期间数据", link: '' }, { name: "隐患排查治理", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'period_risk') {
                        return [{ name: "主页", link: '/' }, { name: "评估期间数据", link: '' }, { name: "风险分级管控", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'risk_c') {
                        return [{ name: "主页", link: '/' }, { name: "风险点详情", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'line_c') {
                        return [{ name: "主页", link: '/' }, { name: "线路详情", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'accident_c') {
                        return [{ name: "主页", link: '/' }, { name: "故障详情", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'rule_l') {
                        return [{ name: "主页", link: '/' }, { name: "评价规范汇总", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'expert') {
                        return [{ name: "主页", link: '/' }, { name: "专业专家信息", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'report_c') {
                        return [{ name: "主页", link: '/' }, { name: "安评报告查询", link: '' }, { name: "安全评价报告下载", link: '' }]
                    } else if (this.$route.name == 'riskEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "风险点录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'reportEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "安评信息录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'accidentEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "实时故障录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'riskCorrectEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "风险整改信息录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'correctEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "风险整改信息录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'lantentDangerEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "隐患排查信息录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'riskCorrectPlanEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "风险整改计划录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'riskReviewEntry') {
                        return [{ name: "主页", link: '/' }, { name: "信息录入", link: '' }, { name: "风险整改后评审信息录入", link: '' }]
                    } else if (this.$route.path.split('/')[2] == 'backup') {
                        return [{ name: "主页", link: '/' }, { name: "数据管理", link: '' }, { name: "备份还原", link: '' }]
                    }else if (this.$route.name == 'aps') {
                        return [{ name: "主页", link: '/' }, { name: "个人设置", link: '' }, { name: "修改密码", link: '' }]
                    }else if (this.$route.name == 'netDescription') {
                        return [{ name: "主页", link: '/' }, { name: "线网基本信息", link: '' }, { name: "线网", link: '' }]
                    }else if (this.$route.name == 'center') {
                        return [{ name: "主页", link: '/' }, { name: "网络化运营", link: '' }, { name: "控制中心功能", link: '' }]
                    }else if (this.$route.name == 'emergency') {
                        return [{ name: "主页", link: '/' }, { name: "网络化运营", link: '' }, { name: "应急能力梳理", link: '' }]
                    }else if (this.$route.name == 'passengerFlow') {
                        return [{ name: "主页", link: '/' }, { name: "网络化运营", link: '' }, { name: "客流专项分析", link: '' }]
                    }else {
                        return []
                    }
                }
            },
        },
        methods: {
            get_line() {
            this.$axios
                .post(this.$url + "/active_option", {
                userToken: localStorage.getItem("current_user_token"),
                ask_data: "lineList"
                })
                .then(
                response => {
                    response.data.res_record.sort(function (a, b) { return parseInt(a.line_code.split('-')[1]) - parseInt(b.line_code.split('-')[1]) });
                    this.nav.content.find(val=>val.code==='line').children[0].children = response.data.res_record;
                }
                );
            },
            me: function (name) {
                this.nav.state = name;
            },
            ml: function () {
                this.nav.state = '';
            },
            subme: function (name) {
                this.nav.substate = name;
            },
            subml: function () {
                this.nav.substate = '';
            },
            toUrl: function (url) {
                if (url) {
                    location.href = url;
                }
            },
            LogOut: function () {
                localStorage.clear();
                this.logined = false;
                location.href = "/login";
            },
            goSearch:function(){
                location.href = "/search/search_result";
            }

        }

    }
</script>

<style scoped>
    a {
        color: inherit;
    }

    a:hover {
        color: inherit;
    }



    a:active {
        color: inherit;
    }

    header {
        width: 100%;
        background-color: #fff;
    }

    .header-inner {
        padding: 15px 0;
        width: 1260px;
        margin: 0 auto;
    }

    .search {
        margin-top: 15px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 25px;
        float: right;
        overflow: hidden;
        height: 27px;
        width: 190px;
        padding: 0px 10px;
    }

    .search-logo {
        padding-top: 5px;
        cursor: pointer;
    }

    .search-input {
        width: 85%;
    }

    .search-input>input {
        width: 100%;
        outline: none;
        height: 27px;
    }

    .logo {
        width: 600px;
        margin-top:15px;
    }

    .pull-left {
        float: left;
    }

    .pull-right {
        float: right;
    }

    .navwrap {
        height: 100px;
        border-bottom: 1px solid #eee;
    }

    .detailNav {
        
        height: 100px;
        overflow: visible;
    }

    .nav {
        width: 100%;
        height: 0;
        margin: 0 auto;
        /* position: relative; */
        background: rgb(255, 255, 255);
        /* overflow: hidden; */
    }

    .navBox {
        width: 1260px;
        height: auto;
        margin: 0 auto;
        background: white;
    }

    .navImg {
        width: 104px;
        height: 53px;
        float: left;
        margin-top: 24px;
    }

    .navImg img {
        width: 100%;
        height: 100%;
    }

    .navUl {
        float: left;
        width: auto;
        height: 100%;
        /* margin-right: 700px; */
    }

    .navLi {
        width: auto;
        height: 100px;
        line-height: 100px;
        font-size: 16px;
        color: rgb(51, 51, 51);
        float: left;
        position: relative;
        cursor: pointer;
        text-align: center;
        margin: 0 70px 0 0;
        list-style: none;
        color: rgb(51, 51, 51);
    }

    .navSpan:hover {
        color: #993333;
    }

    .drag {
        width: 150px;
        height: auto;
        padding: 10px 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
        position: absolute;
        z-index: 9999;
        top: 97px;
        left: -28px;
        border-right: 1px solid rgba(0, 0, 0, .15);
        border-left: 1px solid rgba(0, 0, 0, .15);
        border-bottom: 1px solid rgba(0, 0, 0, .15);
        border-top: 3px solid #993333;
        background: #f9faff;
    }

    .sub-drag {
        border-top:none;
        top: 2px;
        left: 150px;
    }

    .navBor {
        position: absolute;
        width: 1px;
        background: #eeeeee;
        height: auto;
        top: 10px;
        left: 200px;
    }

    .dragLi {
        width: 150px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        text-align: center;
        list-style: none;
        position: relative;
    }

    .dragLi-word {
        width: 100%;
        float: left;
        text-align: center;
    }

    .dragLi-word:hover {
        color: #993333;
        transition: color 0.1s ease;
    }

    .dragAngel {
        width: 0;
        height: 0;
        position: absolute;
        overflow: hidden;
        font-size: 0;
        top: -13px;
        left: 66px;
        line-height: 0px;
        border-width: 0px 10px 10px;
        border-style: solid solid solid solid;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: #993333;
    }
    .med-wrapper-box {

        margin: 0 auto;
        width: 100%;
        min-width: 1250px;
        margin-bottom: 60px;
        background-color: #fff;
    }

    .m-content-wrapper {
        background: #fff;
        width:1260px;
        margin: 0 auto;
    }
    .navSearchBox {
    /* width: auto; */
    height: 46px;
    width: 45%;
    float: right;
    margin-top: 27px;
}
.navSearchIcon {
    width: 46px;
    height: 40px;
    background: #993333;
    /* background: url(../images/1-3.jpg) no-repeat 50% 50%; */
    /* background-size: contain; */
    float: right;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.navSearch {
    width: 250px;
    height: 40px;
    border: none;
    outline: none;
    border: 1px solid #993333;
    float: right;
    padding: 2px 0 2px 10px;
    font-size: 16px;
    line-height: 40px;
}
.placeholder {
    color: #AAA;
}
.navSearchIconIcon {
    width: 20px;
    height: 20px;
    background: url(../assets/search.png) no-repeat 50% 50%;
    /* background-size: 100%; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.bread-wrapper{
    width:1260px;
    margin:20px auto;
}
</style>